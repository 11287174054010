//NavBar

.nav-design{
    background-color: #ff0048;
}

.nav-item-style,.nav-logo{
    color: #fefefe;
    font-size: 18px;
}

.order-nav-item-style{
    background-color: #fefefe;
    color: #ff0048;
    font-size: 18px;
    font-weight: 600;
    margin-left: 20px;
}

a.order-nav-item-style:hover{
    background-color: #ffdd57;
    color: #ff0048;
    font-weight: 800;
}

a.nav-item-style:hover{
    color: #ff0048;
    background-color: #fefefe;
}

.order-button{
    display: none;
}

@media screen and (max-width: 1023px){
    .order-button{
        display: flex;
        width: 100%;
        justify-content: flex-end;
        margin-right: 4%;
    }
}

.navbar-burger{
    color: #fefefe;
    padding-right: 5%;
    &:hover{
        color: #fefefe;
    }
}

.nav-style{
    background-color: #ff0048;
}

//Body

.blog-site{
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.blog-body{
    flex:1;
}

.content-body{
    padding: 5%;
}

@media screen and (max-width: 800px){
    .content-body{
        padding: 8%;
    }
}

//Bio

.bio-banner{
    background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), var(--image-url) center;
	background-size: cover;
    display: block;
    padding: 3%;
    background-attachment: fixed;
    min-height: 80vh;
}

.bio-intro{
    width: 100%;
    padding-top: 2%;
}

.link-boxes{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.blog-boxes{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 3%;
}


.box-style{
    background-color: #ff0048;
    padding: 1%;
    width: 100%;
    margin-top: 2%;
    margin-bottom: 2%;
    margin-left: 1%;
    margin-right: 1%;
    text-align: center;
}

.blog-box{
    margin-top: 10%;
}

.bio-links-intro{
    font-size: 36px;
    color: #fefefe;
    text-transform: capitalize;
    text-align: center;
    font-family: 'Yellowtail', cursive;
}

.box-title{
    font-size: 22px;
    text-align: center;
    font-weight: 600;
    color: #fefefe;
}

.section-title{
    text-align: center;
    font-size: 28px;
    color: #fefefe;
    padding-top: 3%;
    font-family: 'Montserrat';
    font-weight: 600;
}

@media screen and (max-width: 800px){
    .link-boxes{
        display: block;
        padding: 1%;
    }
    .box-style{
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
    .bio-intro{
        padding-top: 8%;
    }
    .box-title{
        font-size: 26px;
    }
}

//Index

.index-banner{
    background: linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.3)), var(--image-url) center;
	background-size: cover;
	min-height: 40vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.banner-text{
    align-items: center;
}

@media screen and (max-width: 1023px){
    .banner-text{
        padding: 5%;
    }
    .order-nav-item-style{
        display: none;
    }
}


.banner-intro{
    color: #fefefe;
    font-size: 40px;
    font-style: italic;
    text-align: center;
}

.blog-card{
    max-height: 500px;
}

.card-title{
    color: #ff0048;
    font-size: 24px;
}


.card-detail{
    display: flex;
    width: 100%;
}

.category-style{
    padding: 0 5px 0 5px;
    background-color: #ffdd57;
    color: #ff0048;
    font-weight: 500;
    text-align: center;
}

.pagination-style{
    width: 100%;
    padding-top: 5%;
    a{
        color: #ff0048;
    }
}

.pagination{
    justify-content: space-evenly;
    font-size: 18px;
}

.filter-list{
    display: flex;
    padding-bottom: 2%;
}

.filter-text{
    color: #ff0048;
    font-size: 24px;
    padding-right: 2%;
    text-transform: capitalize;
}

.image-style{
    object-fit: cover;
    max-height: 400px;
}

.card-image{
    max-height: 400px;
}

@media screen and (max-width: 800px){
    .card-detail{
        font-size: 12px;
    }
    .card-title{
        font-size: 20px;
    }
    .card-content{
        padding: 0.8rem;
    }
    .filter-text{
        font-size: 18px;
    }
}

//About 

.about-banner{
    background: linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.3)), var(--image-url) center;
	background-size: cover;
    min-height: 30vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.sub-banner-text{
    font-size: 42px;
    color: #fefefe;
    text-transform: uppercase;
    font-weight: 800;
    text-align: center;
    margin-top: 5%;
}

@media screen and (max-width: 800px){
    .sub-banner-text{
        font-size: 30px;
    }
}

.about-body{
    padding-top: 5%;
    padding-left: 15%;
    padding-right: 15%;
    font-size: 20px;
    padding-bottom: 5%;
}

.share-icon{
    margin-right: 5%;
}

//Posts

.post-banner{
    background: linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.3)), var(--image-url) center;
	background-size: cover;
	min-height: 40vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.post-banner-text{
    display: block;
}

.share-buttons{
    align-items: center;
    justify-content: center;
    padding-top: 5%;
    padding-bottom: 5%;
}

.more-button{
    align-items: center;
    justify-content: center;
    padding-top: 2%;
}

.button-style{
    margin-right: 15px !important;
    background-color: #ff0048;
    color: #fefefe;
    border: none;
    &:hover{
        background-color: #ffdd57;
        font-weight: 600;
    }
    a{
        color: #fefefe;
        &:hover{
            color: #ff0048;
        }
    }
}

.blog-style{
    font-size: 18px;
    p{
        padding-top: 1%;
        padding-bottom: 1%;
    }

    img{
        justify-content: center;
        display: flex;
        margin: 0 auto;
        max-height: 400px;
    }
    ul{
        padding-left: 50px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    li{
        padding-top: 5px;
        padding-bottom: 5px;
        list-style-type: circle;
    }
    h5,h6{
        display: flex;
        justify-content: center;
        font-style: italic;
    }
    h2{
        padding-top: 15px;
        font-size: 25px;
    }
    h3{
        font-size: 20px;
        font-style: italic;
    }

    @media screen and (max-width: 800px){
        p{
            font-size: 25px;
            padding-top: 4%;
            padding-bottom: 4%;
        }
    }
}

//Footer

.footer-style{
    background-color: #ff0048;
    color: #fefefe;
    padding: 3rem 1.5rem 3rem;
}

.sm-icons{
    color: #fefefe;
    margin-left: 2%;
    margin-right: 2%;
}